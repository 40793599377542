<template>
    <CModal title="Download Report" :show.sync="showModalDownloadFilter_" color="info" size="md" @close="() => { showModalDownloadFilter_ = false }">
        <form @submit.prevent="approve">
			<div class="pl-3 pr-3 pb-3">
                <h5>Choose a filter to download a report</h5>
                <br>
				<CRow class="mb-2">
                    <CCol lg="12" class="mb-3">
						<label class="mb-1">Record Date From</label>
                        <Datepicker v-model="filter.date_from" input-class="form-control" placeholder="" />
					</CCol>
                    <CCol lg="12" class="mb-3">
                        <label class="mb-1">Record Date To</label>
                        <Datepicker v-model="filter.date_to" input-class="form-control" placeholder="" />
                    </CCol>
                    <CCol lg="12" >
						<label class="mb-1">Plate No.</label>
                        <CInput type='text' v-model="filter.plate_no"/>
					</CCol>
                    <CCol lg="12" class="mb-3">
						<label class="mb-1">Vehicle Type</label>
						<v-select label="setting_name" :options="vehicle_type_list.data" :reduce="item => item.id" v-model="filter.vehicle_type_id" placeholder="-Select-"></v-select>
					</CCol>
                    <CCol lg="12" class="mb-3">
						<label class="mb-1">Vehicle Maker</label>
						<v-select label="setting_name" :options="maker_model_list.data" :reduce="item => item.id" v-model="filter.maker_model_id" placeholder="-Select-"></v-select>
					</CCol>
                    
                    <CCol lg="12" class="mb-3">
                        <h6 class="text-danger"> {{error_msg}}</h6>
                    </CCol>
				</CRow>
            </div>
        </form>
        <div slot="footer" class="w-100">
            <!-- <CButton class="ml-1 mr-1 float-right" size="sm" color="info" @click="download"><i class="fa fa-file"></i> Generate Report</CButton> -->
            <!-- <CButton class="ml-1 mr-1 float-right" size="sm" color="light" @click="clear"><i class="fa fa-close"></i> Clear</CButton> -->

            <CButton class="float-right" size="sm" color="success" @click="download" v-if="!processing"><i class="fa fa-file"></i> Generate Report</CButton>
            <a :href="link_download" v-if="ready_download"><i class="fa fa-file"></i> Download Report</a>
            <CButton color="primary" disabled v-if="processing && !ready_download">
                <CSpinner component="span" size="sm" variant="grow" aria-hidden="true"/>
                Generating Report. Please wait ...
            </CButton>
            <CButton class="ml-1 mr-1 float-right" size="sm" color="light" @click="clear" v-if="!processing"><i class="fa fa-close"></i> Clear</CButton>
        </div>
    </CModal>
</template>

<script>
import config from '../../config.js';
import axios from '../../axios';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment';  
import Datepicker from 'vuejs-datepicker';
import 'vue-select/dist/vue-select.css';
export default {
    components: { vSelect, Datepicker },
    mounted() {
        
    },
    props: {
        showModalDownloadFilter: {required: true},
        filters: {}
    },
    data() {
        return {
            showModalDownloadFilter_:false,
            filter: {
				plate_no: '',
				vehicle_type_id:'',
				maker_model_id:'',
				date_to:'',
				date_from:'' 
			},
            vehicle_type_list:[],
            maker_model_list:[],
            depot_list:[],
            no_filter:false,
            currentPage:1,
            error_msg: '',
            processing:false,
            ready_download:false,
            link_download:'',
            report_downloaded : false
        }
    },
    methods: {
        async download() {
            let msg =  'Please select a filter before downloading the report.';
            this.report_downloaded = false;
            if(true || this.dataValidate() && this.dateRangeValidate()){
                this.ready_download = false;
                this.processing = true;
                this.no_filter = false;
                var date_from = this.filter.date_from;
                if(date_from != ""){
                    date_from = moment(date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
                }
                else{
                    date_from = '-'
                }
                var date_to = this.filter.date_to;
                if(date_to != ""){	
                    date_to = moment(date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
                    date_to = date_to + 86399; 
                }else{
                    date_to = '-'
                }
                await axios.post(`${config.api_path}/export-fuel-record`, {
                    
                    company_id:config.global_user_data.company_id,
                    plate_no:this.filter.plate_no ?? "",
                    vehicle_type_id:this.filter.vehicle_type_id ?? "",
                    maker_model_id:this.filter.maker_model_id ?? "",
                    date_from:date_from ?? "",
                    date_to:date_to ?? ""
                   
            })
                // await axios.get(config.api_path+'/export-fuel-record?'+
                // '&company_id='+config.global_user_data.company_id+
                // '&plate_no='+this.filter.plate_no+
                // '&vehicle_type_id='+this.filter.vehicle_type_id+
                // '&maker_model_id='+this.filter.maker_model_id+
                // '&date_from='+date_from+
                // '&date_to='+date_to
                .then(response=>{
                    this.link_download = config.main_path.replace('/index.php', '')+'/'+response.data.file
                    this.watchDownload(response.data.file);
                    this.error_msg = '';
                    // setTimeout(() => {
                    //     this.$showLoading(false)
                    //     window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file)
                      
                    // }, 3000);
                    // setTimeout(() => {
                    //     axios.get(`${config.api_path}/remove-file`,{
                    //         params : {
                    //             filename : response.data.file
                    //         }
                    //     })	
                    // }, 5000);
                    this.no_filter = false;
                })
                .catch(err => {
                    // this.$showLoading(false)
                    this.no_filter = true;
                    this.ready_download = false;
                    this.processing = false;
                });
            }
            else{
                msg = 'Please select record date from and record date to.'
                this.no_filter = true;
                this.ready_download = false;
                this.processing = false;
                this.error_msg = msg;
                this.dateFilterValidatePrompt();
            }
        },
        getVehicleType() {
            axios.get(config.api_path + '/reference/settings-list', {
                params: {setting_type: 'vehicle_type'}
            }).then(response => {
                this.vehicle_type_list = response.data;
            });
        },
        getMakerModal(){
            axios.get(config.api_path+'/reference/settings-list', {
                params:{ setting_type:'maker_model'}
             }) .then(response => {
                this.maker_model_list = response.data;
            });
        },
        clear(){
            this.filter.plate_no = '';
            this.filter.maker_model_id ='',
            this.filter.vehicle_id = '';
            this.filter.vehicle_type_id = '';
            this.filter.date_to = new Date();
            this.filter.date_from = moment(new Date()).subtract(7, 'days')._d;
        },
        dataValidate(){
            if(
                this.filter.plate_no || 
                this.filter.vehicle_type_id || 
                this.filter.maker_model_id ||
                (this.filter.date_from && this.filter.date_to && this.dateRangeValidate)
            ){
                return true;
            }
            else{
                return false;
            }
        },
        dateRangeValidate(){
            let from = this.filter.date_from;
            let to = this.filter.date_to;
            if(!from&&!to){
                return false;
            }else{
                if(from&&to){
                    const start = new Date(from);
                    const end = new Date(to);
                    // Calculate the difference in milliseconds
                    const differenceInMilliseconds = end - start;
                    // Calculate the difference in months
                    const differenceInMonths = differenceInMilliseconds / (1000 * 60 * 60 * 24 * 30);
                    // Check if the difference is not greater than 6 months
                    return differenceInMonths <= 6;
                }
                else{
                    return false;
                }
            }
        },
        dateFilterValidatePrompt(){
            let from = this.filter.date_from;
            let to = this.filter.date_to;
            if(from&&to){
                const start = new Date(from);
                const end = new Date(to);
                // Calculate the difference in milliseconds
                const differenceInMilliseconds = end - start;
                // Calculate the difference in months
                const differenceInMonths = differenceInMilliseconds / (1000 * 60 * 60 * 24 * 30);
                // Check if the difference is not greater than 6 months
                if(!(differenceInMonths <= 6)){
                    this.error_msg = 'Filter date should be maximum of 6 months.';
                }
            }
        },
        watchDownload(file){
            const self = this;
            self.interval_import_program = setInterval(function () {
                axios.get(`${config.api_path}/watch-download`,{
                    params : {
                        filename : file
                    }
                }).then(response => {
                    if(response.data.status){
                        self.ready_download = true;
                        self.processing = false;
                        clearInterval(self.interval_import_program);
                        if(!self.report_downloaded) {
                            self.report_downloaded = true;
                            window.open(config.main_path.replace('/index.php', '')+'/'+file)
                        }
                    }
                });
            }, 1000);
        }
    },
    watch: {
        showModalDownloadFilter_: function(val){
            this.$emit('closeModalDownloadFilter', val)
        },
        showModalDownloadFilter: function(){
            this.showModalDownloadFilter_ = this.showModalDownloadFilter;

            if(this.processing || this.ready_download)
                return;

            if(!this.vehicle_type_list?.data?.length)
                this.getVehicleType();
            if(!this.maker_model_list?.data?.length)
                this.getMakerModal();

            this.download()
        },
        filters: {
            deep: true,
            handler() {
                this.filter = {...this.filters}
                this.ready_download = false;
            }
        }
    },
}
</script>

<style>
.modal-header {
    background-color: white !important;
    
}
.modal-title {
    color: black;
}
</style>

