<template>
<div>
	<CCard class="p-4">
	<CRow>
		
		<CCol lg="6">
				<h5> Fuel Records ({{dataListAll.total}})</h5>
		</CCol>
			<CCol lg="6">
				<CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
						:aria-expanded="showCollapse ? 'true' : 'false'"
						aria-controls="collapse1"
						@click="showCollapse = !showCollapse"
						color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			<CButton 
			v-if="config.getPermission('fuel_record').create"
			size="sm" style="margin-right: 10px" 
			color="success" @click="toggleModal()" 
			class="float-lg-right"><i class="fa fa-plus"></i> Add Fuel Record
			</CButton>
			<CButton 
					v-if="config.getPermission('fuel_record').download"
					size="sm" style="margin-right: 10px" 
					color="btn btn-outline-primary"
					class="float-lg-right"
					@click="() => { showModalDownloadFilter = true }">
					<i class="fa fa-download"></i> Download
			</CButton>

			<!-- <CButton size="sm" component="a" color="primary" 
				v-if="!config.getPermission('fuel_record').download" 
				@click="report" 
				role="button" style="margin-right: 10px; float: right;">	
				<i class="fa fa-download">
			</i> Download </CButton> -->
		</CCol>
	</CRow>
	<CRow> 
			<CCol lg="12">
				<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
					<CCard class="p-4">
						<Search @depot-search-query="search"/>
					</CCard>
					</CCollapse> 
			</CCol>
	</CRow> 
	<hr>

	<CRow>			
		
		<CCol lg="12">  
			
			<CDataTable
				:loading="isLoading"
				:items="dataListAll.data"
				:fields="fields"
				hover
				striped
				outlined
				
			>  
				<template #status="{item}">
						<td style="padding: 5px; width: 100px; text-transform:uppercase;">
					<CBadge 
						style="margin-top:10px"		
						:color="getBadge(item.status)">{{item.status}}
					</CBadge>
						</td>
				</template>
				<template #action="{item}">
					<td style="padding: 5px; width: 100px">
						<CDropdown 
							toggler-text="Select"  
							size="sm"
							color="info"
						>
							<CDropdownItem v-if="config.getPermission('fuel_record').view" @click="viewModal(item)"><i class="fa fa-eye" > View</i></CDropdownItem> 
							<CDropdownItem v-if="config.getPermission('fuel_record').update" @click="updateModal(item)"><i class="fa fa-pencil-square-o" > Update</i> </CDropdownItem>
							<CDropdownItem v-if="config.getPermission('fuel_record').delete" @click="deleteRow(item)"><i class="fa fa-trash" > Delete</i></CDropdownItem> 
						</CDropdown>
					</td>
				</template>
			</CDataTable> 
			<CPagination
				:active-page.sync="currentPageAll"
				:pages="Math.ceil(dataListAll.total / 20)"
				:activePage="currentPageAll"
				@update:activePage="updatePageAll"
			/>	
		</CCol>
	</CRow>

	
	<!-- CREATE/UPDATE -->
	<CModal :title="'Fuel Record'" :show.sync="formModal" color="info" >
		<form @submit.prevent="submit"> 
			<CRow>
				<CCol lg="12">
					<div class="form-group"> 
					<label>
					Vehicle
						<span class="text-danger">*</span>
					</label>
						<v-select 
							label="plate_no" 
							:options="vehicleList.data"
							:reduce="item => item.id"
							v-model="dataParams.vehicle_id"
							placeholder="-Select-"
							@input="vehicleChange"
						>
							<template #search="{attributes, events}">
								<input
									class="vs__search"
									:required="!dataParams.vehicle_id"
									v-bind="attributes"
									v-on="events"
								/>
							</template>
						</v-select>
					</div> 
				</CCol> 
			</CRow>  
			
			<CRow>
				<CCol lg="12">
					<label>
						Reading <span class="text-danger">*</span>
					</label>
					<i class="fa fa-question-circle ml-1" title="Reading should be maximum of 6 characters." ></i>
					
					<CInput
					    v-mask="'######'"
						type="number"
						placeholder=""
						:class="{'has-error' : odoHasError}"
						:disabled="validating_odometer"
						v-model="dataParams.odo_reading"
						@change="odometerChanged"
						required
					/> 
					<div class="mb-3" style="font-size:11px" >
						<span>Current Odometer: 
							<span v-if="!data_loading.odometer">
								{{ dataParams.current_odometer ? dataParams.current_odometer : 0 }} KM</span> 
							<span v-else><i class="fa fa-spin fa-loading"></i> Fetching ....</span>
						</span>
					</div>
				</CCol>
				
			</CRow>
			<CRow class="mb-3">
					
					<CCol lg="12">
						<label>
							Record Date
							<span class="text-danger">*</span>
						</label>
						<Datepicker 
							input-class="form-control bg-white" 
							@selected="dateSelected"
							v-model="dataParams.date"
							placeholder="" 
							required
							:typeable="true"
						>
						</Datepicker>
						
					</CCol> 
					
				</CRow>
		
			<CRow>
				<CCol lg="12">
						<label>
						Liters <span class="text-danger">*</span>
					</label>
					<CInput  
						required
						type="number"
						placeholder=""
						v-model="dataParams.liters" 
					/>
				</CCol> 
			</CRow>
			<CRow>
				<CCol lg="12">
						<label>
						Fuel Type
					</label>
					<CInput   
						placeholder=""
						v-model="dataParams.unit" 
					/>
				</CCol> 
			</CRow>
		
			<CRow>
				<CCol lg="12">
						<label>
						References 
					</label>
					<CInput   
						placeholder=""
						v-model="dataParams.reference" 
					/>
				</CCol> 
			</CRow>
			<CRow>
				<CCol lg="12">
						<label>
						Remarks 
					</label>
					<CInput   
						placeholder=""
						v-model="dataParams.remarks" 
					/>
				</CCol> 
			</CRow>
			<!-- <span class="text-danger">{{message}}</span>	 -->
			
			<div slot="footer" class="w-100">
				<CButton type="submit" :disabled="odoHasError"  style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right btn-sm">
					Save
				</CButton>
				
				<CButton style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right btn-sm" @click="formModal = false">
					Cancel
				</CButton>
				
			</div>
			
		</form>
		<div slot="footer" class="w-100">
		</div>
	</CModal>

	<!-- VIEWING DETAILS -->
	<CModal title="Fuel Record" :show.sync="showViewModal" color="info" > 
		
			<CRow class="mb-2">
				<CCol lg="12">
					<h3>{{dataParams.vehicle_label}}</h3>
				</CCol>
			</CRow>
			
			<CRow class="mb-2">
				<CCol lg="4">
					Record Date
				</CCol>
				<CCol lg="8">
					{{dataParams.date_label}}
				</CCol>
			</CRow>
			<CRow class="mb-2">
				<CCol lg="4">
					Odometer
				</CCol>
				<CCol lg="8">
					{{(dataParams.odo_reading && dataParams.odo_reading != "")  ?  dataParams.odo_reading : "-"}} km
				</CCol>
			</CRow>
			<CRow class="mb-2">
				<CCol lg="4">
					Liters
				</CCol>
				<CCol lg="8">
					{{(dataParams.liters && dataParams.liters != "")  ?  dataParams.liters : "-"}}
				</CCol>
			</CRow>
			<CRow class="mb-2">
				<CCol lg="4">
					Fuel Type
				</CCol>
				<CCol lg="8">
					{{(dataParams.unit && dataParams.unit != "")  ?  dataParams.unit : "-"}}
				</CCol>
			</CRow>
			<CRow class="mb-2">
				<CCol lg="4">
					References
				</CCol>
				<CCol lg="8">
					{{(dataParams.reference && dataParams.reference != "")  ?  dataParams.reference : "-"}}
				</CCol>
			</CRow>
			<CRow class="mb-2">
				<CCol lg="4">
					Remarks
				</CCol>
				<CCol lg="8">
					{{dataParams.remarks? dataParams.remarks : "-"}}
				</CCol>
			</CRow> 
		<!-- </div> -->
		
		<div slot="footer" class="w-100">
			<!-- <CButton style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="showViewModal = false">
				Close
			</CButton> 
				-->
		</div>
		<div slot="footer" class="w-100"></div>
	</CModal>
	</CCard>
	<download-filter :filters="filter" :showModalDownloadFilter="showModalDownloadFilter" @closeModalDownloadFilter="closeModalDownloadFilter"/>
</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import '../../style.css';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment';  
import { thisTypeAnnotation } from '@babel/types';
import downloadFilter from './fuel_filter_report.vue';
export default {
mounted(){ 
	this.getData();
	this.getVehicleList();
},
data(){
	return{
		config,
		title:'',
		formModal: false,
		showViewModal: false,
		dataListAll: [],
		data_loading: {
			odometer: false
		},
		dataParams: {
			vehicle_id: "",
			reading: "",
			date: "",
			liters: "",
			unit: "",
			reference: "",
			remarks: "",
			odo_reading: ''
		},
		fields: [
			{
				key: 'vehicle_label', 
				label: 'Plate No.'
			},
			{
				key: 'vehicle_maker', 
				label: 'Vehicle Maker'
			},
			{
				key: 'vehicle_type', 
				label: 'Type'
			},
			
			{
				key: 'liters_label', 
				label: 'Liters'
			},
			{
				key: 'odometer_label', 
				label: 'Odometer Reading'
			},
			// {
			// 	key: 'fuel_economy', 
			// 	label: 'Fuel Economy'
			// },
			
			{
				key: 'date_label', 
				label: 'Record Date'
			},
			{
				key: 'remarks_label', 
				label: 'Remarks'
			},
			{
				key: 'action',
				label: 'Action',
				_style: { width: '20px'}
			}
		],
		currentPageAll: 1,
		editMode: false,
		selectedRow: {},
		showCollapse: false,
		isLoading: false,
		filter: {
			plate_no: '',
			vehicle_type_id:'',
			maker_model_id:'',
			date_to: '', // new Date(),
			date_from: '', //moment(new Date()).subtract(7, 'days')._d, 
		},
		
		vehicleList: {
			data:[]
		},
		message: "",
		validating_odometer: false,
		odo_error: false,
		showModalDownloadFilter:false,
	}
},
name: 'Tables',
	components: {
		Search, 
		Datepicker, 
		vSelect, 
		downloadFilter,
	},
methods: {
	report(){
		var date_from = this.filter.date_from;
		if(date_from != ""){
			date_from = moment(date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
		}
		else{
			date_from = '-'
		}
		var date_to = this.filter.date_to;
		if(date_to != ""){	
			date_to = moment(date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
			date_to = date_to + 86399; 
		}else{
			date_to = '-'
		}
		console.log(this.filter);
		window.location.href = (config.api_path+'/export-fuel-record?'+
		'&company_id='+config.global_user_data.company_id+
		'&plate_no='+this.filter.plate_no+
		'&vehicle_type_id='+this.filter.vehicle_type_id+
		'&maker_model_id='+this.filter.maker_model_id+
		'&date_from='+date_from+
		'&date_to='+date_to);
	},
	dateSelected(ev) {
		this.$nextTick(() => {
			this.dataParams.date = this.dataParams.date ? moment(this.dataParams.date).format('MM/DD/YYYY') : null; 
			this.odometerChanged(this.dataParams.odo_reading)
		});
	},
	odometerChanged(ev) {
		this.odo_error = false;
		this.validating_odometer = true;
		axios.post(`${config.api_path}/odometer/validate`,{
			vehicle_id : this.dataParams.vehicle_id,
			date: this.dataParams.date,
			reading : ev,
		}).then(res => {
			const {status, message} = res.data;
			if(status == 'error') {
				let text = message;
				Swal.fire({
					title: 'Ooops!',
					text,
					icon: 'error', 
				})
				this.odo_error = true;
			}
			this.validating_odometer = false;

		}).catch(err => {
			this.validating_odometer = false;
		})
	},
		getBadge (status) {
	return status === 'active' ? 'success'
			: status === 'inactive' ? 'danger' : 'primary'
},
	
	updatePageAll(data){
		this.currentPageAll = data;
		this.getData();
	}, 
	
	toggleModal(){
		this.formModal = !this.formModal;
		// this.title = "Add User";
		this.ResetField();

	},
	ResetField() {                  
		this.dataParams.vehicle_id =null,
		this.dataParams.date= null,
		this.dataParams.odo_reading= "",
		this.dataParams.liters= "",
		this.dataParams.unit= "",
		this.dataParams.reference= "",
		this.dataParams.remarks_= ""
		this.dataParams.current_odometer=""

},

	submit(){
		var ax = {};
		var temp_date = this.dataParams.date;
		this.dataParams.date = moment(this.dataParams.date).startOf('day').unix();
		if(this.dataParams.vehicle_id == ""){
			this.dataParams.vehicle_id = null;
		} 
		if(this.editMode){
			ax = axios.put(config.api_path+"/fuel_record/"+this.selectedRow.id, this.dataParams)
		}
		else{
			ax = axios.post(config.api_path+"/fuel_record", this.dataParams)
		}
		ax.then(response => {
			if(response.data.status == "failed"){
				this.message = response.data.message;
				this.dataParams.date = temp_date;
				return;
			}
			this.message = ""
	// 		if(response.data.status == 'failed'){
	// 			Swal.fire({
				// 	title: 'Error!',
				// 	text: response.data.message,
				// 	icon: 'error', 
				// })
				// return;
	// 		}
			var text = 'Fuel record successfully added!';
			if(this.editMode){
				text = 'Fuel record successfully updated!';
			}
			Swal.fire({
				title: 'Success!',
				text,
				icon: 'success', 
			})
			this.formModal = false
			this.editMode = false;
			this.dataParams = {
				vehicle_id: "",
				reading: "",
				date: "",
				liters: "",
				unit: "",
				reference: "",
				remarks: ""
			}
			this.getData(); 
		})
	},

	search(event){
		this.filter = event; 
		this.getData(); 
	},

	getData(){ 

		this.isLoading = true;

		var maker_model_id = this.filter.maker_model_id;
		if(maker_model_id == "all" || maker_model_id == null){
			maker_model_id = ""
		}

		var vehicle_type_id = this.filter.vehicle_type_id;
		if(vehicle_type_id == "all" || vehicle_type_id == null){
			vehicle_type_id = ""
		}

		var date_from = this.filter.date_from;
		if(date_from != ""){
			date_from = moment(date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
		}

		var date_to = this.filter.date_to;
		if(date_to != ""){
			date_to = moment(date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
			date_to = date_to + 86399; 
		}

		axios.get(config.api_path+'/fuel_record?status=&maker_model_id='+maker_model_id+'&vehicle_type_id='+vehicle_type_id+'&date_from='+date_from+'&date_to='+date_to+'&plate_no='+this.filter.plate_no+'&page='+this.currentPageAll+'&limit=20')
		.then(response => {
			this.dataListAll = response.data; 
			this.dataListAll.data = this.dataListAll.data.map((value, index)=>{
				value.vehicle_maker = "N/A";
				value.vehicle_type = "N/A";
				if(value.vehicle){
					value.vehicle_label = value.vehicle.plate_no;
					value.vehicle_maker = value.vehicle.maker_model ? value.vehicle.maker_model.setting_name : "-"
					value.vehicle_type = value.vehicle.vehicle_type ? value.vehicle.vehicle_type.setting_name : "-"
				}
				else{
					value.vehicle_label = "-";
				} 

				value.remarks_label = "-";
				if(value.remarks && value.remarks != ""){
					value.remarks_label = value.remarks;
				}

				value.liters_label = "-";
				if(value.liters && value.liters != ""){
					value.liters_label = value.liters;
				}

				value.date_label = "-";
				if(value.date && value.date != ""){
					value.date_label = moment.unix(value.date).format('MM/DD/YYYY')
				}

				value.odometer_label = "-";
				if(value.odo_reading && value.odo_reading != ""){
					value.odometer_label = value.odo_reading+" km"
				}

				value.fuel_economy = "-";

				return value;
			}); 
		})
		.catch(err => {
			console.error(err)
			this.isLoading = false;
		})
		.finally(fin => {
			console.log(fin)
			this.isLoading = false;	
		})
	}, 

	updateModal(item){
		this.vehicleChange(item.vehicle_id);
		this.selectedRow = item;
		this.dataParams = item;
		this.dataParams.date = moment.unix(this.dataParams.date).format('MMMM DD, YYYY')
		this.formModal = true;
		this.editMode = true;
		// this.title = "Update User";
		

	},

	viewModal(item){

		this.selectedRow = item;
		this.dataParams = item;
		this.showViewModal = true; 

	},

	deleteRow(item){
		Swal.fire({
			icon:'warning',
			title: 'Are you sure you want to delete this Fuel record?', 
			showCancelButton: true,
			confirmButtonText: `Yes`, 
		}).then((result) => { 
			if (result.isConfirmed) {
				axios.delete(config.api_path+'/fuel_record/'+item.id)
				.then(response => {
					this.getData();  
					Swal.fire({
						title: 'Success!',
						text: "Fuel record successfully deleted",
						icon: 'success', 
					})
				}) 
			}
		})  
	},

	


	getVehicleList(){

		axios.get(config.api_path+'/reference/vehicle-list',{
			params:{
			
		}
			})
		.then(response => {
			this.vehicleList = response.data; 
		})
	},

	

	vehicleChange(i){ 
		// var filter = this.vehicleList.data.filter((value, index)=>{
		// 	return this.dataParams.vehicle_id == value.id;
		// })[0];

		// if(filter.odo_reading != null){
		// 	this.dataParams.reading = filter.odo_reading.reading;
		// } 
		this.data_loading.odometer = true;
			axios.get(`${config.api_path}/vehicle/odometer/${i}`).then(res => {
				const {data, status} = res.data;
				const odo = data?.odo_reading ?? "0";

				this.$set(this.dataParams,'current_odometer', odo);
				this.data_loading.odometer = false;
			})
			.finally(fin => {
				this.data_loading.odometer = false;
			})
		
	},

	closeModalDownloadFilter(status){
		this.showModalDownloadFilter = status;
	},

},

computed:{
	odoHasError() {
		return this.odo_error;
	}
}
}
</script>
<style>
.has-error {
	border: 1px solid red !important;
}
</style>