<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="3">
          <CInput v-model="plate_no" placeholder="Plate No."/>
        </CCol>
        <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="makerModelList.data"
            :reduce="item => item.id"
            v-model="maker_model_id"
            placeholder="Vehicle Maker"
          > 
          </v-select>  
        </CCol>
        
        <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="vehicleTypeList.data"
            :reduce="item => item.id"
            v-model="vehicle_type_id"
            placeholder="Vehicle Type"
          > 
          </v-select> 
        </CCol> 
        
      </CRow>
      <CRow>
        <CCol lg="3">
          <Datepicker v-model="date_from" input-class="form-control" placeholder="Record Date from" />
        </CCol>
        <CCol lg="3">
          <Datepicker v-model="date_to" input-class="form-control" placeholder="Record Date To" />
        </CCol>
        <!-- <CCol lg="3"> 
        </CCol> -->
        <!-- <CCol lg="1">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block @click="clearSearch" color="info">Clear Search</CButton>
        </CCol> -->
        <CCol lg="3" class="float-right mt-1">
          <CButton size="sm" color="info" variant="outline" style="margin-right: 10px"  @click="search">Search</CButton>
       
          <CButton size="sm" color="info" variant="outline" @click="clearSearch" style="margin-right: 10px"  >Clear Filter</CButton>
         
        </CCol>
      </CRow> 
  </div>
</template>

<script>
  import config from '../../config.js';
  import axios from '../../axios';
  import vSelect from 'vue-select'
  import Datepicker from 'vuejs-datepicker';
  import 'vue-select/dist/vue-select.css';
    export default {
        name: "BookingTypeSearch",
        data() {
            return {
              plate_no: "",
              maker_model_id: "",
              vehicle_type_id: "",
              date_from: "",
              date_to: "", 

              makerModelList: {
		          data: []
		        },
		        
		        vehicleTypeList: {
		          data: []
		        }, 
            }
        },
      mounted() {
        this.getVehicleType();
		this.getMakerModel();
      },
      components: {vSelect, Datepicker},
      methods: {
        search() {
          const data = {
            plate_no: this.plate_no,
            maker_model_id: this.maker_model_id,
            vehicle_type_id: this.vehicle_type_id,
            date_from: this.date_from,
            date_to: this.date_to,  
          }
          this.$emit("depot-search-query", data)
        },
        clearSearch() {
          this.plate_no = "";
          this.maker_model_id = "";
          this.vehicle_type_id = "";
          this.date_from = "";
          this.date_to = "";   
          const data = {
            plate_no: this.plate_no,
            maker_model_id: this.maker_model_id,
            vehicle_type_id: this.vehicle_type_id,
            date_from: this.date_from,
            date_to: this.date_to,  
          }
          this.$emit("depot-search-query", data)
        },

       
      getVehicleType(){

        axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'vehicle_type'
            }
              })
        .then(response => {
          this.vehicleTypeList = response.data; 
        })

        },


      getMakerModel(){

        axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'maker_model'
            }
              })
        .then(response => {
          this.makerModelList = response.data; 
        })

        },

	   
      }
    }
</script>

<style scoped>

</style>
